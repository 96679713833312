import React, { Component } from 'react'
import { Provider } from 'react-redux'
import operatorStore from '../../../store/admin_store'

import Video from '../../../components/common/video'
import PackingList from './packing_list'
import Modal from 'react-responsive-modal';
import { playErrorSound } from '../../../components/common/sounds/sounds'

import printJS from 'print-js'

import SerialNumberModal from './serial_number_modal'

const store = operatorStore()

const SETTING_QC_OPERATOR = 'setting_qc_operator'
const SETTING_PACKING_OPERATOR = 'setting_packing_operator'
const WAITING = "waiting"
const CHECKING = "checking"
const WAITING_CONFIRM = "waiting_confirm"
const INPUT_SERIAL_NUMER = "input_serial_number"
const PRINTING = 'printing'
const WAITING_FOR_SHIPPING_LABEL = 'waiting_for_shipping_label'
const MAX_VIDEO_LENGTH = 900000 // 30 minutes
const VIDEO_RECORDING_COUNTDOWN = 60000
// const MAX_VIDEO_LENGTH = 10000

// const UPLOADING = 'uploading'
const DONE = "done"
const PACK_BARCODE = "-DOPACKING-"
const CONFIRM_BARCODE = "-CONFIRM-"
const DEFAULT_STATE = {
  fast_print: false,
  id: null,
  name: '',
  shop_name: '',
  products: [],
  status: SETTING_QC_OPERATOR,
  highlight: null,
  requesting_shipping: false,
  ready_to_packing: [],
  packings: [],
  shipping_accounts: [],
  serial_number_modal_open: false,
  modal_open: false,
  modal_product_uid: null,
  modal_product_index: null,
  modal_quantity: 0,
  modal_shippings_open: false,
  packing_index: 0,
  qc_operator: null,
  start_time: null,
  packing_operator: null,
  uploading_videos: 0,
  serial_number_confirm: () => { },
  serial_number_cancel: () => { },
  consumables: [],
  order_status: null,
  auto_print_shipping_mode: true,
  auto_print_order_mode: false,
  accept_batch: false,
  shipping_label_base64: null,
  shipping_label_type: null,
  apiConfirming: false,
  waitingUploadOrderId: null,
  order_note: '',
  internal_note: '',
  shipping_type_name: '',
  auto_selected_shipping_account: null,
  need_serial_numbers_count: 0,
  sending_data: false,
  attached_printers: [],
  consumable_upadted_after_done: false,
}


export default class Qc extends Component {
  constructor(props) {
    super(props)
    this.state = DEFAULT_STATE
    this.state.print_packing = false
    this.onBarcodeInput = this.onBarcodeInput.bind(this)
    this.onRecordEnd = this.onRecordEnd.bind(this)
    this.resetOrder = this.resetOrder.bind(this)
    this.checkCompleteness = this.checkCompleteness.bind(this)
    this.confirmOrder = this.confirmOrder.bind(this)
    this.moreShipping = this.moreShipping.bind(this)
    this.batchCheck = this.batchCheck.bind(this)
    this.onBatchCheckClick = this.onBatchCheckClick.bind(this)
    this.oversize = this.oversize.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.modalQuantityChange = this.modalQuantityChange.bind(this)
    this.onPrintPacking = this.onPrintPacking.bind(this)
    this.currentProductRef = null
    this.addToPacking = this.addToPacking.bind(this)
    this.pack = this.pack.bind(this)
    this.onPressPacking = this.onPressPacking.bind(this)
    this.onCloseShippingModal = this.onCloseShippingModal.bind(this)
    this.onProductError = this.onProductError.bind(this)
    this.onConfirmError = this.onConfirmError.bind(this)
    this.getOperator = this.getOperator.bind(this)
    this.onClearAllDone = this.onClearAllDone.bind(this)
    this.getOrder = this.getOrder.bind(this)
    this.onPrintOrderPdf = this.onPrintOrderPdf.bind(this)
    this.onPrintInvoicePdf = this.onPrintInvoicePdf.bind(this)
    this.checkConsumables = this.checkConsumables.bind(this)
    this.onAutoPrintSwitchPress = this.onAutoPrintSwitchPress.bind(this)
    this.onAcceptBatchSwitchPress = this.onAcceptBatchSwitchPress.bind(this)
    this.readOutExpirationDate = this.readOutExpirationDate.bind(this)
    this.readOutBatch = this.readOutBatch.bind(this)
    this.fetchLabelBase64 = this.fetchLabelBase64.bind(this)
    this.printBase64 = this.printBase64.bind(this)
    this.preloadShipingLabel = this.preloadShipingLabel.bind(this)
    this.countdownForVideoUpload = this.countdownForVideoUpload.bind(this)
    this.setShippingAccountByConsumables = this.setShippingAccountByConsumables.bind(this)
    this.downloadOrderPdf = this.downloadOrderPdf.bind(this)
    this.downloadShippingMarkPdf = this.downloadShippingMarkPdf.bind(this)
    this.onAutoPrintOrderSwithPress = this.onAutoPrintOrderSwithPress.bind(this)
    this.onRecordStart = this.onRecordStart.bind(this)
    this.videoRecordingClipTimer = null
    this.onRecordingPause = this.onRecordingPause.bind(this)
    this.onRecordingResume = this.onRecordingResume.bind(this)
    this.updateConsumables = this.updateConsumables.bind(this)

  }

  printBase64(base64, resolve) {
    printJS({
      printable: base64.split(',').slice(-1),
      type: 'pdf',
      base64: true,
      onLoadingEnd: () => {
        resolve()
      }
    });
  }

  preloadShipingLabel(order_id) {
    if (!order_id || !this.state.fast_print) return
    fetch(`/operator/orders/${order_id}/preprint_label.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipping_account_id: this.state.shipping_account_id})
    }).then(response => response.json()).then(data => {
      if (data.shipping_id) {
        let retry = 0
        let timerid = setInterval(() => {
          retry += 1
          fetch(`/operator/orders/${order_id}/shippings/${data.shipping_id}/logistic_status`, {
            method: 'GET',
            headers: { 'X-CSRF-Token': this.props.authenticity_token },
            credentials: 'same-origin'
          }).then(res => res.json())
            .then(data => {
              if (data.logistic_generator_status == 'complete') {
                if (data.logistic_pdf_path) {
                  /* file_type: pdf or url */
                  if (data.file_type == 'pdf') {
                    this.fetchLabelBase64(data.logistic_pdf_path, 'pdf')
                  }
                  if (data.file_type == 'url' && data.logistic_pdf_path.split('.').pop() == 'pdf') {
                    this.fetchLabelBase64(data.logistic_pdf_path, 'pdf')
                  }
                  if (data.file_type == 'image') {
                    this.fetchLabelBase64(data.logistic_pdf_path, 'image')
                  }
                }
                clearInterval(timerid)
              } else if (data.logistic_generator_status == 'failed' || retry > 10) {
                clearInterval(timerid)
              }
            })
        }, 1000)
      }
    })
  }

  fetchLabelBase64(url, shipping_label_type) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader();
        reader.onload = () => {
          this.setState({ shipping_label_base64: reader.result, shipping_label_type: shipping_label_type })
        }
        reader.readAsDataURL(blob);
      });

  }


  confirmOrder() {
    let force_close = false
    if (this.state.products.length > 0 && this.state.status != WAITING_CONFIRM) {
      if (confirm("訂單尚未QC完成，確認要強制結案?")) {
        force_close = true
      } else {
        return
      }
    }
    if (this.state.apiConfirming) {
      return
    }
    this.setState({ apiConfirming: true })
    if (force_close || this.state.order_status != 'done' || confirm("此訂單狀態為已出貨，確認要覆蓋原先紀錄嗎?")) {
      this.pack(true).then(() => {
        let time_length = (new Date() - this.state.start_time) / 1000
        fetch(`/operator/orders/${this.state.id}/confirm`,
          {
            method: 'POST',
            headers: {
              'X-CSRF-Token': this.props.authenticity_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              qc_operator_id: this.state.qc_operator.id,
              packing_operator_id: this.state.packing_operator.id,
              time_length: time_length,
              products: this.state.products,
              force_close: force_close,
              auto_print_shipping_mode: this.state.auto_print_shipping_mode && !this.props.print_ahead ? 'true' : '',
              consumables: this.state.consumables.filter(e => e.quantity),
              shipping_account_id: this.state.shipping_account_id
            }),
            credentials: 'same-origin'
          }).then(res => res.json())
          .then(data => {
            if (data.success) {
              this.setState({order_status: 'done'})
              if(data.waiting_for_more_shippings){
                this.setState({ status: WAITING_FOR_SHIPPING_LABEL })
                fetch(`/operator/orders/${this.state.id}/request_provided_shipping_labels`,
                {
                  method: 'POST',
                  headers: {
                    'X-CSRF-Token': this.props.authenticity_token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  credentials: 'same-origin'
                }).then(res => res.json())
                .then(data => {
                  if(data.success)
                  {
                    let retry = 0
                    let is_querying = false
                    let timerid = setInterval(() => {
                      retry += 1
                      if (is_querying) return
                      if (retry > 300) {
                        clearInterval(timerid)
                        notyError(`作業逾時，請稍候至系統信箱查看`)
                        this.setState({ status: DONE })
                      }
                      is_querying = true
                      fetch(`/operator/orders/${this.state.id}/shippings/combine_append`, {
                        method: 'POST',
                        headers: { 'X-CSRF-Token': this.props.authenticity_token },
                        credentials: 'same-origin'
                      }).then(res => res.json())
                        .then(data => {
                          is_querying = false
                          if(data.success && data.base64){
                            clearInterval(timerid)
                            this.printBase64(data.base64,() => {
                              this.setState({ status: DONE, apiConfirming: false })
                            })
                          }
                        })
                   },2000)
                  }else{
                    notyError(`${data.message}`)
                    this.setState({ status: DONE })
                  }
                })
              }else{
                if (this.state.need_printing && this.state.auto_print_shipping_mode && !this.props.print_ahead) {
                  this.onPrintInvoicePdf(data.order_id).then(() => {
                    this.onPrintShipping(data.order_id, data.shipping_id).then(() => {
                      this.setState({ status: DONE, apiConfirming: false })
                  })
                  })
                } else {
                  this.setState({ status: DONE, apiConfirming: false })
                }
              }
            }
          })
      })

      this.refs.order_input.value = ""
    }
  }
  onCloseModal() {
    this.refs.order_input.value = ""
    this.setState({ modal_open: false })
  }

  onCloseShippingModal() {
    this.setState({ modal_shippings_open: false })
  }

  modalQuantityChange(e) {
    const product = this.state.products.find(product => {
      return (
        product.uid === this.state.modal_product_uid &&
        product.storage_type_name === this.state.modal_storage_type_name &&
        product.expiration_date === this.state.modal_product_expiration_date &&
        product.batch === this.state.modal_product_batch
      )
    })
    console.log('product', product)
    const value = parseInt(e.currentTarget.value)
    if (isNaN(value) || value < 0) {
      this.setState({ modal_quantity: 0 })
    } else if (value > product.quantity - product.scanned) {
      this.setState({ modal_quantity: product.quantity - product.scanned })
    } else {
      this.setState({ modal_quantity: value })
    }
  }
  onClearAllDone() {
    var order_id = this.state.id
    this.refs.video_recorder.stop_recording().then(() => {
      this.resetOrder()
      this.getOrder(`${order_id}`.padStart(8, '0')).then(() => {
        if (this.props.print_ahead && this.state.auto_print_shipping_mode) {
          this.moreShipping(1)
        }
        if (this.state.auto_print_order_mode) {
          this.onPrintOrderPdf(this.state.id)
        }
      })
    })

  }
  getOperator(id, status) {
    fetch(`/operator/accounts/${id}.json`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        if (data.success) {
          if (status == SETTING_QC_OPERATOR && data) {
            this.setState({ qc_operator: data.operator, status: SETTING_PACKING_OPERATOR })
          }
          if (status == SETTING_PACKING_OPERATOR && data) {
            this.setState({ packing_operator: data.operator, status: WAITING })
          }
        } else {
          alert('找不到人員帳號，請掃描人員條碼')
        }
      })
  }

  onRecordStart(order_id,order_name) {
    this.refs.video_recorder.start_recording()
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    this.videoRecordingClipTimer = setTimeout(()=>{
      this.refs.video_recorder.stop_recording().then((streaming) => {
        this.onRecordEnd(streaming, order_id, order_name).then(() => {
          this.onRecordStart(order_id,order_name)
        })
      })
    },MAX_VIDEO_LENGTH)
  }

  onRecordingResume(){
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    this.videoRecordingClipTimer = setTimeout(()=>{
      this.refs.video_recorder.stop_recording().then((streaming) => {
        this.onRecordEnd(streaming, this.state.id, this.state.name).then(() => {
          this.onRecordStart(this.state.id,this.state.name)
        })
      })
    },MAX_VIDEO_LENGTH)
  }

  onRecordingPause(){
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
  }

  async onRecordEnd(stream, order_id, order_name) {
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    return new Promise((resolve, reject) => {
      require('es6-promise').polyfill();

      let originalFetch = require('isomorphic-fetch');
      let fetch = require('fetch-retry')(originalFetch);
      if (stream) {
        let date = new Date()
        let fileName = `${order_name}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}.webm`;
        let file = new File([stream], fileName, {
          type: 'video/webm'
        });
        let data = new FormData();
        data.append('file', file, fileName)
        // let current_status = this.state.status
        // this.setState({ status: UPLOADING })
        this.setState({ uploading_videos: this.state.uploading_videos + 1, waitingUploadOrderId: null })
        resolve()
        fetch(`/operator/orders/${order_id}/upload_video`,
          {
            retryDelay: 3000,
            retryOn: function (attempt, error, response) {
              if (error !== null || response.status >= 400) {
                if (attempt < 3) {
                  notyError(`上傳失敗，重新嘗試上傳中`)
                  return true
                } else {
                  notyError(`上傳錄影檔失敗`)
                  return false
                }
              }
            },
            method: 'POST',
            headers: {
              'X-CSRF-Token': this.props.authenticity_token,
            },
            body: data,
            credentials: 'same-origin'
          }
        ).then(res => {
          // this.setState({status:current_status})
          this.setState({ uploading_videos: this.state.uploading_videos - 1 })
        }).catch(error => {
          notyError(`上傳失敗`)
          this.setState({ uploading_videos: this.state.uploading_videos - 1 })
          resolve()
        })
      } else {
        this.setState({ uploading_videos: this.state.uploading_videos - 1 })
        resolve()
      }
    })
  }

  onPrintPacking(packing_id) {
    return new Promise((resolve, reject) => {
      let previous_state = this.state.status
      this.setState({ status: PRINTING })
      if (packing_id) {
        let retry = 0
        let timerid = setInterval(() => {
          retry += 1
          if (retry > 60) {
            clearInterval(timerid)
            notyError(`作業逾時`)
            this.setState({ status: previous_state })
            resolve()
            retry = 0
          }
          fetch(`/operator/orders/${this.state.id}/packings/${packing_id}/pdf_status`, {
            method: 'GET',
            headers: { 'X-CSRF-Token': this.props.authenticity_token },
            credentials: 'same-origin'
          }).then(res => res.json())
            .then(data => {
              console.log(data)
              if (data.status == 'complete') {
                console.log(data)
                clearInterval(timerid)
                printJS({
                  printable: `/operator/orders/${this.state.id}/packings/${packing_id}.pdf`, onLoadingEnd: () => {
                    this.setState({ requesting_shipping: false })
                    resolve()
                  }
                })
                // workaround , using timeout instead of print dialog event (printJs 1.5 & chrome bug)
                setTimeout(() => {
                  document.getElementById('printing-overlay')?.addEventListener('mousemove',
                    () => {
                      this.setState({ requesting_shipping: false })
                      resolve()
                    })
                }, 6000)
              } else if (data.status == 'failed') {
                console.log(data)
                notyError(`裝箱明細錯誤! ${data.note}`)
                clearInterval(timerid)
                resolve()
              }
            })
        }, 1000)
      }
    })
  }

  onPrintInvoicePdf(order_id) {
    return new Promise((resolve, reject) => {
      if (this.state.need_invoice) {
        this.setState({ status: PRINTING })
        if (order_id) {
          let retry = 0
          let timerid = setInterval(() => {
            retry += 1
            if (retry > 60) {
              clearInterval(timerid)
              notyError(`作業逾時`)
              resolve()
              retry = 0
            }
            fetch(`/operator/orders/${order_id}/invoice_status`, {
              method: 'GET',
              headers: { 'X-CSRF-Token': this.props.authenticity_token },
              credentials: 'same-origin'
            }).then(res => res.json())
              .then(data => {
                if (data.success) {
                  clearInterval(timerid)
                  printJS({
                    printable: `/operator/orders/${order_id}/invoice_pdf`, onPrintDialogClose: () => {
                      // rollback to onPrintDialogClose (or it will close immediately because of shipping label)
                      resolve()
                    }
                  })
                  setTimeout(() => {
                    document.getElementById('printing-overlay')?.addEventListener('mousemove',
                      () => {
                        resolve()
                      })
                  }, 6000)
                }
              })
          }, 2000)
        }
      } else {
        resolve()
      }
    })
  }


  onPrintShipping(order_id, shipping_id) {
    return new Promise((resolve, reject) => {
      this.setState({ status: PRINTING })
      if (this.state.shipping_label_base64) {
        this.printBase64(this.state.shipping_label_base64, resolve)
      } else {
        if (order_id && shipping_id) {
          let retry = 0
          let timerid = setInterval(() => {
            retry += 1
            if (retry > 60) {
              clearInterval(timerid)
              notyError(`作業逾時`)
              this.setState({ requesting_shipping: false })
              resolve()
              retry = 0
            }
            fetch(`/operator/orders/${order_id}/shippings/${shipping_id}/logistic_status`, {
              method: 'GET',
              headers: { 'X-CSRF-Token': this.props.authenticity_token },
              credentials: 'same-origin'
            }).then(res => res.json())
              .then(data => {
                console.log(data)
                if (data.logistic_generator_status == 'complete') {
                  clearInterval(timerid)
                  if (data.logistic_pdf_path) {
                    /* file_type: pdf or url */
                    if (data.file_type == 'pdf' || data.file_type == 'url') {
                      console.log("QC printing pdf")
                      console.log("Path:", data.logistic_pdf_path)
                      printJS({
                        printable: data.logistic_pdf_path,
                        onLoadingEnd: () => {
                          this.setState({ requesting_shipping: false })
                          resolve()
                        },
                        onError: () => {
                          // this.setState({ requesting_shipping: false })
                          // resolve()
                        }
                      })
                      setTimeout(() => {
                        document.getElementById('printing-overlay')?.addEventListener('mousemove',
                          () => {
                            this.setState({ requesting_shipping: false })
                            resolve()
                          })
                      }, 6000)
                    }
                    if (data.file_type == 'image') {
                      printJS({
                        printable: data.logistic_pdf_path, type: 'image', onLoadingEnd: () => {
                          console.log('onLoadingEnd Image')
                          this.setState({ requesting_shipping: false })
                          resolve()
                        },
                        onError: () => {
                          // this.setState({ requesting_shipping: false })
                          // resolve()
                        }
                      })
                      setTimeout(() => {
                        document.getElementById('printing-overlay')?.addEventListener('mousemove',
                          () => {
                            this.setState({ requesting_shipping: false })
                            resolve()
                          })
                      }, 6000)
                    }
                    if (data.file_type == 'html') {
                      // sad
                      // $("#printArea").html(data.logistic_pdf_path)
                      // let mywindow = window.open('', 'PRINT', 'height=400,width=600');

                      let printPage = window.open("", "Printing...", "");
                      printPage.document.open();
                      printPage.document.write("<HTML><head></head><BODY onload='window.print();window.close()'>");
                      printPage.document.write("<PRE>");
                      printPage.document.write(data.logistic_pdf_path);
                      printPage.document.write("</PRE>");
                      printPage.document.close("</BODY></HTML>");
                      this.setState({ requesting_shipping: false })
                      resolve()

                      // return true;
                      // printJS({
                      //   printable: 'printArea', type: 'html', onLoadingEnd: () => {
                      //     this.setState({ requesting_shipping: false })
                      //     resolve()
                      //   }
                      // })

                    }
                    if (data.file_type == 'execute_html') {
                      let newWindow = window.open();
                      newWindow.document.write(data.logistic_pdf_path)
                      this.setState({ requesting_shipping: false })
                      resolve()
                    }
                  }
                  else {
                    notyErrorBlock(`託運單錯誤! ${data.error_message}`)
                    this.setState({ requesting_shipping: false })
                    resolve()
                  }
                  clearInterval(timerid)
                } else if (data.logistic_generator_status == 'failed') {
                  console.log(data)
                  notyErrorBlock(`託運單錯誤! ${data.error_message}`)
                  this.setState({ requesting_shipping: false })
                  clearInterval(timerid)
                  resolve()
                }
              })
          }, 1000)
        }
      }
    })
  }
  moreShipping(quantity) {
    let current_status = this.state.status
    this.setState({ requesting_shipping: true, status: PRINTING, shipping_label_base64: null })
    fetch(`/operator/orders/${this.state.id}/more_shipping`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ quantity: quantity ,shipping_account_id: this.state.shipping_account_id}),
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.success) {
          if (!!data.shipping_id) {
            notyMessage("已追加，列印中")
            this.onPrintShipping(data.order_id, data.shipping_id).then(() => {
              this.setState({ status: current_status })
            })
          }
          else {
            notyMessage("已要求託運單")
            this.setState({ status: current_status })
          }
        }
        else {
          notyErrorBlock(`託運單錯誤! ${data.message}`)
          this.setState({ status: current_status })
        }
      })
  }

  pack(isConfirm = false) {
    console.log('pack!!!')
    return new Promise((resolve, reject) => {
      if(this.videoRecordingClipTimer){
        clearTimeout(this.videoRecordingClipTimer)
        this.videoRecordingClipTimer = null
      }
      if (this.state.ready_to_packing.length == 0) {
        resolve()
        return
      }
      fetch(`/operator/orders/${this.state.id}/pack`,
        {
          method: 'POST',
          body: JSON.stringify({
            print_packing: this.state.print_packing,
            items: this.state.ready_to_packing.map(p => {
              return {
                product_id: p.product_id,
                quantity: p.quantity,
                batch: p.batch,
                serial_numbers: p.serial_numbers.filter(e => e)
              }
            })
          }),
          headers: {
            'X-CSRF-Token': this.props.authenticity_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'same-origin'
        }).then(res => res.json())
        .then(data => {
          if (this.state.print_packing) {
            let current_status = this.state.status
            this.onPrintPacking(data.packing_id).then(() => {
              this.setState({
                status: current_status,
                ready_to_packing: [],
                packings: data.packings,
                packing_index: data.packings.length
              })
              if (!isConfirm) {
                this.refs.video_recorder.stop_recording().then((streaming) => {
                  this.onRecordEnd(streaming, this.state.id, this.state.name).then(() => {
                    resolve()
                  })
                })
              } else {
                this.setState({ waitingUploadOrderId: this.state.id })
                this.countdownForVideoUpload(VIDEO_RECORDING_COUNTDOWN,this.state.id)
                resolve()
              }
            })
          } else {
            this.setState({
              ready_to_packing: [],
              packings: data.packings,
              packing_index: data.packings.length
            })
            if (!isConfirm) {
              this.refs.video_recorder.stop_recording().then((streaming) => {
                this.onRecordEnd(streaming, this.state.id, this.state.name).then(() => {
                  console.log('end!')
                  resolve()
                })
              })
            } else {
              this.setState({ waitingUploadOrderId: this.state.id })
              this.countdownForVideoUpload(VIDEO_RECORDING_COUNTDOWN,this.state.id)
              resolve()
            }
          }
        })
    })

  }

  countdownForVideoUpload(ms, countdownOrderId) {
    setTimeout(() => {
      if (this.state.waitingUploadOrderId == countdownOrderId) {
        this.refs.video_recorder.stop_recording().then((streaming) => {
          this.onRecordEnd(streaming, this.state.id, this.state.name)
        })
      }
    }, ms)
  }


  componentDidMount() {
    document.body.onkeydown = (e) => {
      if (!this.state.modal_open && !this.state.modal_shippings_open && !this.state.serial_number_modal_open) {
        this.refs.order_input.focus()
      }
    }
    window.onbeforeunload = () => {
      if (this.state.uploading_videos > 0) {
        return "影片上傳中，確定離開頁面？"
      }
      if (this.state.waitingUploadOrderId) {
        return "影片上傳中，確定離開頁面？"
      }
    }
    fetch("http://127.0.0.1:52444/printers",{
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      method:'GET',
      mode: "cors", // no-cors, cors, *same-origin
    }).then(res=>res.json()).then(data=>{
      console.log('====printers====')
      console.log(data.printers)
      this.setState({attached_printers: data.printers})
    })
  }
  componentDidUpdate() {
    if (this.currentProductRef) {
      window.scrollTo(0, this.currentProductRef.offsetTop)
    }

  }

  static normalizeOrder(data) {
    console.log(data)
    let _ret = {}
    let scanned_product = {}
    let order = data.order
    let packings = data.packings
    for (let packing of packings) {
      for (let item of packing.packing_items) {
        if (scanned_product[`${item.product_id}-${item.batch}`]) {
          scanned_product[`${item.product_id}-${item.batch}`] += item.quantity
        } else {
          scanned_product[`${item.product_id}-${item.batch}`] = item.quantity
        }
      }
    }
    _ret.id = order.id
    _ret.name = order.name
    _ret.order_status = order.status
    _ret.order_note = order.note
    // _ret.oversize_alarm = order.is_cod
    _ret.oversize_alarm = true
    // enable oversize alarm for all shipping types
    _ret.shop_name = order.shop.name
    _ret.order_barcode = order.barcode
    _ret.need_printing = order.need_print_shipping
    _ret.more_shipping_available = order.more_shipping_available
    _ret.need_invoice = order.need_invoice
    _ret.picking_list_id = order.picking_list_id
    _ret.picking_index = order.picking_index
    _ret.ready_to_packing = []
    _ret.products = []
    _ret.shipping_mark = order.shop_channel.shipping_mark
    console.log(scanned_product)
    order.products.forEach((element, index) => {
      let scanned_quantity = 0
      if (scanned_product[`${element.product.id}-${element.batch}`]) {
        if (element.quantity > scanned_product[`${element.product.id}-${element.batch}`]) {
          scanned_quantity = scanned_product[`${element.product.id}-${element.batch}`]
          scanned_product[`${element.product.id}-${element.batch}`] = 0
        } else {
          scanned_quantity = element.quantity
          scanned_product[`${element.product.id}-${element.batch}`] -= element.quantity
        }
      }
      const product_index = _ret.products.findIndex(product => {
        return product['id'] == element.product.id &&
          product['batch'] == element.batch &&
          product['expiration_date'] == element.expiration_deadline &&
          product['storage_type_name'] == element.storage_type_name
      })

      if (product_index > -1) {
        _ret.products[product_index].quantity += element.quantity
        _ret.products[product_index].scanned += scanned_quantity
      } else {
        _ret.products.push({
          order_item_id: element.id,
          index: index,
          name: element.product.name,
          barcode: element.product.barcode,
          quantity: element.quantity,
          note: element.note,
          internal_note: element.internal_note,
          scanned: scanned_quantity,
          expiration_date: element.expiration_deadline,
          batch: element.batch,
          uid: element.product.uid,
          id: element.product.id,
          storage_type_name: element.storage_type_name,
          product_storage_type_id: element.product_storage_type_id,
          box_barcode: element.product.box_barcode,
          mid_unit_barcode: element.product.mid_unit_barcode,
          mid_amount: element.product.mid_amount,
          default_pcs: element.product.default_pcs,
          need_serial_number: element.product.need_serial_number,
          secondary_barcodes: element.product.secondary_barcodes
        })
      }
    });
    return _ret
  }

  resetOrder() {
    let { packing_operator, qc_operator, status, auto_print_shipping_mode, accept_batch, uploading_videos,attached_printers,auto_print_order_mode,fast_print,  ...default_order_state } = DEFAULT_STATE
    default_order_state.status = WAITING
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    this.setState(default_order_state)
  }


  async updateConsumables(){
    await fetch(`/operator/orders/${this.state.id}/update_consumable.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        consumables: this.state.consumables.filter(e => e.quantity)
      })
    }).then(response => {
      notyMessage('更新成功', 3000)
    }).catch(e => {
      notyError('更新失敗', 3000)
    })
    this.setState({ consumable_upadted_after_done: false })

  }

  onProductError() {
    notyErrorBlock("商品錯誤!")
    playErrorSound(this.props.operator_error_sound)
  }
  onConfirmError() {
    notyError("結單條碼錯誤!")
    playErrorSound(this.props.operator_error_sound)
  }

  checkCompleteness() {
    if (this.state.products.length == 0)
      this.confirmOrder()
    else {
      for (let product of this.state.products) {
        if (product.quantity != product.scanned) {
          return
        }
      }
      this.setState({ status: WAITING_CONFIRM })
    }
  }

  onBatchCheckClick(uid, index) {
    let products = this.state.products
    for (let product of products) {
      if (product.uid == uid && product.index == index) {
        this.refs.video_recorder.resume_recording()
        this.setState({
          modal_product_uid: uid,
          modal_product_expiration_date: product.expiration_date,
          modal_storage_type_name: product.storage_type_name,
          modal_product_batch: product.batch,
          modal_product_index: index,
          modal_open: true,
          modal_quantity: product.quantity - product.scanned
        })
        return
      }
    }
  }

  batchCheck() {
    let products = this.state.products
    this.setState({ modal_open: false })
    for (let product of products) {
      if (product.uid == this.state.modal_product_uid && product.index == this.state.modal_product_index) {
        new Promise((resolve, reject) => {
          if (product.need_serial_number) {
            this.setState({ serial_number_modal_open: true,
              current_product: product,
              need_serial_numbers_count: this.state.modal_quantity, serial_number_cancel: reject, serial_number_confirm: resolve })
          } else {
            resolve()
          }
        }).then((serial_numbers) => {
          this.setState({ serial_number_modal_open: false ,current_product: null})
          this.addToPacking(product, this.state.modal_quantity, serial_numbers)
          product.scanned += Math.min(this.state.modal_quantity, product.quantity - product.scanned)
          this.setState({ products: products, highlight: product.index })
          this.checkCompleteness()

        })
      }
    }
  }

  addToPacking(product, quantity, serial_numbers) {
    let ready_to_packing = this.state.ready_to_packing
    let packing_item = ready_to_packing.find((item) =>
      item.product_id == product.id && item.batch == product.batch)

    if (packing_item) {
      packing_item.quantity += quantity
      if (serial_numbers) {
        packing_item.serial_numbers = packing_item.serial_numbers.concat(serial_numbers)
      }
    }
    else {
      ready_to_packing.push({
        product_id: product.id,
        quantity: quantity,
        uid: product.uid,
        name: product.name,
        batch: product.batch,
        serial_numbers: serial_numbers ? serial_numbers : []
      })
    }

    this.setState({
      ready_to_packing: ready_to_packing
    })
  }

  onPressPacking() {
    this.setState({sending_data:true})
    let msg = new SpeechSynthesisUtterance(`第${this.state.packing_index+2}箱開始作業`);
    window.speechSynthesis.speak(msg)
    this.pack().then(() => {
      this.setState({sending_data:false})
      if (this.props.print_ahead) {
        this.moreShipping(1)
      }
      this.onRecordStart(this.state.id,this.state.name)
    }).reject(()=>{
      this.setState({sending_data:false})
    })
  }

  readOutExpirationDate(product) {
    if (!!product.expiration_date && this.state.has_different_expiration_dates.includes(product.id)) {
      try {
        var msg = new SpeechSynthesisUtterance(`${product.expiration_date}`);
        window.speechSynthesis.speak(msg)
      } catch (e) {

      }
    }
  }
  readOutBatch(product) {
    if (!!product.batch && this.state.has_different_batches.includes(product.id)) {
      try {
        var msg = new SpeechSynthesisUtterance(`批號${product.batch}`);
        window.speechSynthesis.speak(msg)
      } catch (e) {

      }
    }
  }

  getOrder(barcode) {
    return new Promise((resolve, reject) => {
      fetch(`/operator/orders/barcode/${barcode}?auto_print_shipping_mode=${this.state.auto_print_shipping_mode}`, {
        'credentials': 'same-origin'
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == "success") {
            this.setState(
              Object.assign({
                status: CHECKING,
                shelves: data.shelves,
                packings: data.packings,
                packing_index: data.packings.length,
                start_time: new Date(),
                has_different_expiration_dates: data.has_different_expiration_dates,
                has_different_batches: data.has_different_batches,
                shipping_type_name: data.shipping_type_name,
                consumables: data.consumables,
                waitingUploadOrderId: null
              }, Qc.normalizeOrder(data))
            )
            this.setState({ shipping_accounts: data.shipping_accounts })
            if(data.shipping_accounts.length > 0){
              let preferred_shipping_account = data.shipping_accounts.find((account)=>{
                if(account.policy == "weight" && account.policy_threshold && data.order.weight >= account.policy_threshold){
                  return true
                }
                if(account.policy == "cuft" && account.policy_threshold && data.order.cuft >= account.policy_threshold){
                  return true
                }
                return false
              })
              if(preferred_shipping_account){
                this.setState({shipping_account_id: preferred_shipping_account.id,auto_selected_shipping_account_id: preferred_shipping_account.id})
              }else{
                if(this.state.manual_set_shipping_account_id && data.shipping_accounts.map((account)=>account.id).includes(this.state.manual_set_shipping_account_id)){
                  this.setState({shipping_account_id: this.state.manual_set_shipping_account_id})
                }else{
                  this.setState({shipping_account_id: data.shipping_accounts[0].id})
                }
              }
            }else{
              this.setState({shipping_account_id: null})
            }
            if (this.state.auto_print_shipping_mode && data.order.need_print_shipping) {
              this.preloadShipingLabel(data.order.id)
            }
            this.onRecordStart(data.order.id,data.order.name)
            this.refs.video_recorder.setCaption(`訂單編號: ${this.state.name}`, 2000)
            $.noty.closeAll();
            this.checkCompleteness()
            resolve()
          } else {
            alert(data.message)
            reject()
          }
        });
    })
  }


  checkConsumables(barcode) {
    let consumables = this.state.consumables
    for (let consumable of consumables) {
      if (consumable.barcode == barcode) {
        consumable.quantity = consumable.quantity ? consumable.quantity + 1 : 1
        this.setState({ consumables: consumables })
        this.setShippingAccountByConsumables(consumables)
        return true
      }
    }
    return false
  }

  setShippingAccountByConsumables(consumables) {
    let maxCuft = 0
    for(let consumable of consumables){
      if(consumable.quantity > 0 && consumable.cuft && consumable.cuft > maxCuft){
        maxCuft = consumable.cuft
      }
    }
    if(maxCuft == 0){
      return
    }
    let shipping_account = this.state.shipping_accounts.find((account)=>{
      if(account.policy == "cuft" && account.policy_threshold && maxCuft >= account.policy_threshold){
        return true
      }
      return false
    })
    if(shipping_account){
      this.setState({shipping_account_id: shipping_account.id})
    }
  }

  compareBarcodeForProducts(product,scanned_barcode) {
    if(!product.barcode) {
      return false
    }
    let product_barcode = product.barcode.trim().toUpperCase()
    scanned_barcode = scanned_barcode.trim().toUpperCase()
    if (product.secondary_barcodes.includes(scanned_barcode)){
      return true
    }else if(product_barcode.length == 12 && scanned_barcode.length == 13 && scanned_barcode[0] == '0' ){
      return product_barcode == scanned_barcode.substring(1)
    }else if(product_barcode.length == 13 && scanned_barcode.length == 12 && product_barcode[0] == '0' ){
      return product_barcode.substring(1) == scanned_barcode
    }
    else{
      return product_barcode == scanned_barcode
    }
  }

  async onBarcodeInput(e) {
    if(this.state.sending_data){
      return
    }
    if (this.state.modal_open) {
      this.refs.order_input.value = ""
      return
    }
    if (e.keyCode == 13 && this.refs.order_input.value) {
      if (this.refs.order_input.value == PACK_BARCODE) {
        this.refs.order_input.value = ""
        this.onPressPacking()
        return
      }
      switch (this.state.status) {
        case SETTING_QC_OPERATOR:
        case SETTING_PACKING_OPERATOR:
          this.getOperator(this.refs.order_input.value, this.state.status)
          this.refs.order_input.value = ""
          break
        case DONE:
          if (this.checkConsumables(this.refs.order_input.value)) {
            this.refs.order_input.value = ""
            // 完成訂單後允許掃描耗材
            this.setState({ consumable_upadted_after_done: true })
            return
          }
          if(this.state.consumable_upadted_after_done){
            // 掃描耗材後再次結單
            await this.updateConsumables()
          }
          if (this.state.waitingUploadOrderId) {
            let streaming = await this.refs.video_recorder.stop_recording()
            await this.onRecordEnd(streaming, this.state.waitingUploadOrderId, this.state.name)
          }
          this.resetOrder()
        case WAITING:
          this.getOrder(this.refs.order_input.value).then(() => {
            if (this.props.print_ahead && this.state.auto_print_shipping_mode) {
              this.moreShipping(1)
            }
            if (this.state.auto_print_order_mode) {
              this.onPrintOrderPdf(this.state.id)
            }
          })
          this.refs.order_input.value = ""
          break
        case CHECKING:
          let barcode_value = this.refs.order_input.value
          this.refs.order_input.value = ""

          if (barcode_value == CONFIRM_BARCODE) {
            this.confirmOrder()
            return
          } else {
            let products = this.state.products
            barcode_value = barcode_value.trim()
            for (let product of products) {
              if ((this.compareBarcodeForProducts(product,barcode_value) || this.state.accept_batch && barcode_value == product.batch) && product.scanned < product.quantity) {
                new Promise((resolve, reject) => {
                  if (product.need_serial_number) {
                    this.setState({ serial_number_modal_open: true,
                      current_product: product,
                      need_serial_numbers_count: 1, serial_number_cancel: reject, serial_number_confirm: resolve })
                  } else {
                    resolve()
                  }
                }).then((serial_numbers=[]) => {
                  product.scanned = product.scanned + 1
                  this.setState({ serial_number_modal_open: false ,current_product: null})
                  this.refs.order_input.value = ''
                  this.refs.video_recorder.resume_recording()
                  this.refs.video_recorder.setCaption(`${product.uid} ${product.name}`, 2000)
                  this.setState({ products: products, highlight: product.index })
                  this.addToPacking(product, 1, serial_numbers)
                  this.readOutExpirationDate(product)
                  this.readOutBatch(product)

                  this.checkCompleteness()
                  return
                }, () => {
                  console.log('reject!')
                  this.setState({ serial_number_modal_open: false })
                })
                return
              } else if (product.box_barcode && product.default_pcs && product.box_barcode.toUpperCase() == barcode_value.toUpperCase()
                && product.scanned + product.default_pcs <= product.quantity) {
                product.scanned = product.scanned + product.default_pcs
                console.log('box scan!')
                this.refs.video_recorder.resume_recording()
                this.refs.video_recorder.setCaption(`${product.uid} ${product.name}`, 2000)
                this.readOutExpirationDate(product)
                this.readOutBatch(product)
                this.addToPacking(product, product.default_pcs)
                this.setState({ products: products, highlight: product.index })
                this.checkCompleteness()
                return
              } else if (product.mid_unit_barcode && product.mid_amount && product.mid_unit_barcode.toUpperCase() == barcode_value.toUpperCase()
                && product.scanned + product.mid_amount <= product.quantity) {
                product.scanned = product.scanned + product.mid_amount
                console.log('box scan!')
                this.readOutExpirationDate(product)
                this.readOutBatch(product)
                this.refs.video_recorder.resume_recording()
                this.refs.video_recorder.setCaption(`${product.uid} ${product.name}`, 2000)
                this.addToPacking(product, product.mid_amount)
                this.setState({ products: products, highlight: product.index })
                this.checkCompleteness()
                return
              }
            }
          }

          if (this.checkConsumables(barcode_value)) {
            return
          }
          this.onProductError()
          break
        case INPUT_SERIAL_NUMER:
          break
        case WAITING_CONFIRM:
          if (this.checkConsumables(this.refs.order_input.value)) {
            this.refs.order_input.value = ""
            return
          }
          if (this.refs.order_input.value == this.state.order_barcode || this.refs.order_input.value == CONFIRM_BARCODE || this.refs.order_input.value == this.state.name) {
            this.confirmOrder()
          } else {
            this.onConfirmError()
          }
          break
      }

    }
  }

  oversize() {
    fetch(`/operator/orders/${this.state.id}/oversize`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => {
        notyMessage("已發送超材通知", 3000)
        this.refs.video_recorder.stop_recording().then(() => { this.resetOrder() })
      })
  }
  onAutoPrintSwitchPress(event) {
    console.log(event.currentTarget.checked)
    this.setState({ auto_print_shipping_mode: event.currentTarget.checked })
  }

  onAutoPrintOrderSwithPress(event) {
    console.log(event.currentTarget.checked)
    this.setState({ auto_print_order_mode: event.currentTarget.checked })
  }

  onAcceptBatchSwitchPress(event) {
    this.setState({ accept_batch: event.currentTarget.checked })
  }

  onPrintOrderPdf(order_id){
    this.downloadOrderPdf(order_id,(data)=>{
      let shared_url = data.shared_url
      if(shared_url){
        fetch(shared_url).then(res=>res.blob()).then(blob=>{
          let file = new File([blob], `${this.state.name}.pdf`, {type: "application/pdf"})
          let formData = new FormData();
          formData.append('pdf', file);
          formData.append('printerName', document.querySelector('select[name="printer"]').value)

          fetch('http://127.0.0.1:52444/printers', {
            method: 'POST',
            mode: 'cors',
            body: formData,
          }).then(res=>res.json()).then(data=>{
            if(data.error){
              notyError(`列印失敗，${data.error}`)
            }
          }).catch(e=>{
            notyError("列印失敗"+e)
          })

        })

      }else{
        notyError("失敗，請手動下載")
      }

    })
  }

  downloadOrderPdf(order_id,callback){
    fetch(`/operator/orders/${order_id}/generate_pdf`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json()).then(data=>{
        if(callback){
          let retries = 0
          let interval = setInterval(()=>{
            fetch(`/operator/tasks/${data.job_id}.json`).then(res=>res.json()).then(data=>{
              if(data.status == 'complete'){
                callback(data)
                clearInterval(interval);
              }else{
                retries += 1
              }
            })
            if(retries >= 60){
              clearInterval(interval)
            }
          },1000)
        }else{
          pushTaskItem(data.job_id,'pdf')
        }
      })
  }

  downloadShippingMarkPdf = (order_id) => {
    fetch(`/operator/orders/${order_id}/shipping_marks.json`).then(res => res.json()).then(data => {
      pushTaskItem(data.job_id, 'pdf')
    })
  }

  render() {
    let count = 0
    let oversize_button = ''
    let footer_buttons = ''
    let more_shipping_btn = ''
    let download_order_pdf_button = ''
    let download_shipping_mark_pdf_button = ''
    if (this.state.id && this.state.oversize_alarm) {
      oversize_button = <a className='btn btn-danger' onClick={this.oversize}>
        超材通知
      </a>

    }
    if (this.state.id){
      download_order_pdf_button = <a className='btn btn-info btn-outline' onClick={() => {
        this.downloadOrderPdf(this.state.id)
      }}>
        下載訂單
      </a>
    }
    if (this.state.id && !this.state.requesting_shipping && this.state.need_printing && this.state.order_status == 'done') {
      if(this.state.more_shipping_available){
        more_shipping_btn = <a className='btn btn-primary'
        onClick={() => { this.setState({ modal_shippings_open: true }) }}>
        印託運單
      </a>
      }else{
        more_shipping_btn = <a className='btn btn-primary'
        onClick={() => {
          this.moreShipping(1)
        }}>
        印託運單
        </a>
      }
    }

    if (this.state.id && this.state.shipping_mark && this.state.packings.length > 0) {
      download_shipping_mark_pdf_button = <a className='btn btn-warning btn-outline' onClick={() => {
        this.downloadShippingMarkPdf(this.state.id)
      }}>
        下載嘜頭
      </a>
    }

    footer_buttons = <div className="panel-footer">
      <div className='row'>
        <div className='col-md-8'>
          <span className='col-md-4'>
            <label className="switcher switcher-success" htmlFor="switcher-basic">
              <input id="switcher-basic" type="checkbox"
                checked={this.state.auto_print_shipping_mode}
                onChange={this.onAutoPrintSwitchPress}
              />
              <div className="switcher-indicator">
                <div className="switcher-yes">是</div>
                <div className="switcher-no">否</div>
              </div>
              自動列印託運單
            </label>
          </span>
          <span className='col-md-4'>
            <label className="switcher switcher-success" htmlFor="switcher-accept-batch">
              <input id="switcher-accept-batch" type="checkbox"
                checked={this.state.accept_batch}
                onChange={this.onAcceptBatchSwitchPress}
              />
              <div className="switcher-indicator">
                <div className="switcher-yes">是</div>
                <div className="switcher-no">否</div>
              </div>
              允許覆核批號
            </label>
          </span>
          <span className='col-md-4'>
            <label className="switcher switcher-success" htmlFor="switcher-fast-print">
              <input id="switcher-fast-print" type="checkbox"
                checked={this.state.fast_print}
                onChange={(e) => { this.setState({ fast_print: e.currentTarget.checked }) }}
              />
              <div className="switcher-indicator">
                <div className="switcher-yes">是</div>
                <div className="switcher-no">否</div>
              </div>
              快速列印
            </label>
          </span>
        </div>
        <span className='col-md-4'>
          <div className='btn-group pull-right'>
            {download_order_pdf_button}
            {oversize_button}
            {more_shipping_btn}
            {download_shipping_mark_pdf_button}
          </div>
        </span>

      </div>
      { this.state.attached_printers.length > 1 ?
        <div className='row'>
          <div className='col-md-8'>
          <span className='col-md-4'>
            <label className="switcher switcher-success" htmlFor="switcher-auto-print-order">
              <input id="switcher-auto-print-order" type="checkbox"
                checked={this.state.auto_print_order_mode}
                onChange={this.onAutoPrintOrderSwithPress}
              />
              <div className="switcher-indicator">
                <div className="switcher-yes">是</div>
                <div className="switcher-no">否</div>
              </div>
              自動列印訂單
            </label>
          </span>
          {
            this.state.auto_print_order_mode ?
            <span className='col-md-4'><select className='form-control' name='printer'>{
              this.state.attached_printers.map(printer=><option value={printer}>{printer}</option>)
            }</select> </span>: ''

          }
        </div> </div>: ''}

    </div>
    if (!this.state.qc_operator || !this.state.packing_operator) {
      return <Provider store={store}>
        <div>
          <div className="page-header">
            <div className="pull-left"><h1>訂單出貨</h1></div>
            <div className="pull-right">
              <input type="text" ref="order_input"
                disabled={this.state.sending_data}
                placeholder="請依序掃描QC及包裝人員條碼"
                autoFocus={true}
                onKeyDown={this.onBarcodeInput}
                className="form-control input-lg" />
            </div>
          </div>
        </div>
      </Provider>
    }
    return (<Provider store={store}>
      <div>
        <Modal open={this.state.modal_open} onClose={this.onCloseModal} animationDuration={10}
          onEntered={() => { this.refs.quantity_input.focus() }}
          center>
          <div className='form-group'>
            <label htmlFor="form-group-input-1">請輸入數量</label>
            <input type="number" className="form-control" onChange={this.modalQuantityChange}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  this.batchCheck()
                }
              }}

              id="form-group-input-1" ref="quantity_input" value={this.state.modal_quantity} autoFocus={true}
            />
            <hr />
            <a className='btn btn-primary' onClick={() => {
              this.batchCheck()
              console.log(this.refs.quantity_input.value)
            }}>確認</a>
          </div>
        </Modal>
        <Modal open={this.state.serial_number_modal_open} showCloseIcon={false}
          // onEntered={() => { this.refs.serial_number_input.focus() }}
          center>
            <SerialNumberModal
              quantity={this.state.need_serial_numbers_count}
              currentProduct={this.state.current_product}
              operator_error_sound={this.props.operator_error_sound}
              confirmSerialNumbers={this.state.serial_number_confirm}
            ></SerialNumberModal>
        </Modal>
        <Modal open={this.state.modal_shippings_open} onClose={this.onCloseShippingModal}
          onEntered={() => { this.refs.shipping_quantity_input.focus() }}
          center>
          <div className='form-group'>
            <label htmlFor="form-group-input-1">請輸入件數</label>
            <input type="number" className="form-control"
              id="form-group-input-1" ref="shipping_quantity_input" defaultValue={this.state.packings.length || 1}
            />
            <hr />
            <a className='btn btn-primary' onClick={() => {
              console.log(this.refs.shipping_quantity_input.value)
              if (this.refs.shipping_quantity_input.value > 0) {
                this.moreShipping(this.refs.shipping_quantity_input.value)
              }
              this.setState({ modal_shippings_open: false })
            }}>列印</a>
          </div>
        </Modal>
        {
          this.state.status == PRINTING ?
            <div className="loading" id='printing-overlay'>列印中</div> : ''
        }
        {
          this.state.status == WAITING_FOR_SHIPPING_LABEL ?
            <div className="loading" id='printing-overlay'>等待更多托運單</div> : ''
        }
        {/* {
          this.state.status == UPLOADING ?
            <div className="loading">錄影上傳中</div> : ''
        } */}

        <div className="page-header">
          <div className="pull-left">
            <h1>訂單出貨</h1>
          </div>

          <div className="pull-right col-md-3">
            <input type="text" ref="order_input"
              placeholder="請輸入或者掃描訂單條碼"
              autoFocus={true}
              onKeyDown={this.onBarcodeInput}
              className="form-control input-lg" />
          </div>
          <div className='pull-right col-md-3'>
            <div>
              <h4>QC人員: {this.state.qc_operator.name}</h4>
              <br />
              <h4>包裝人員: {this.state.packing_operator.name}</h4>
            </div>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='panel'>
            <div className='panel-heading'>
              {this.state.order_status == "done" || this.state.status == DONE ? <span className='label label-success'>已出貨</span> : ''}
              <span>&nbsp;&nbsp;</span>
              {this.state.id ?
                <span>
                  <div className='col-md-4'>
                    <a href={`/operator/orders/${this.state.id}`} target="_blank">
                      {[this.state.name, this.state.shop_name].join(' / ')}
                    </a>
                    <span className='badge badge-primary'>
                      {this.state.shipping_type_name}
                    </span>
                  </div>
                  <div className='col-md-3'>
                    {
                      this.state.shipping_accounts.map((account, index) => {
                        return <a key={index} className={`btn ${this.state.auto_selected_shipping_account_id == account.id ? 'btn-warning' : 'btn-primary' } btn-block ${this.state.shipping_account_id != account.id ? 'btn-outline' : ''}`} onClick={()=>{
                          this.setState({manual_set_shipping_account_id: account.id})
                          this.setState({shipping_account_id:account.id,shipping_label_base64: null })
                          fetch(`/operator/orders/${this.state.id}/clear_preprint_label`,
                          {
                            method: 'POST',
                            headers: {
                              'X-CSRF-Token': this.props.authenticity_token,
                            },
                            credentials: 'same-origin'
                          }).then(res => {
                          })


                        }}>物流客代: {[account.customer_name,account.customer_code].filter(e=>e).join('/')}</a>
                      }
                    )
                    }
                  </div>

                  <span className='col-md-2'>
                    揀貨單號:
                    <a href={`/operator/picking_lists/${this.state.picking_list_id}`} target="_blank">
                      {this.state.picking_list_id}
                    </a>
                  </span>
                  <span className='col-md-1'>
                    批次序號:{this.state.picking_index}
                  </span>
                  <span>
                    {`訂單總PCS: ${this.state.products && this.state.products.length > 0 ? this.state.products.reduce((sum, p) => {return sum + p.quantity},0) : 0 }`}
                  </span>
                </span> : '請掃描訂單'}
              {this.state.id ?
                <a className='btn btn-default pull-right' onClick={() => {
                  this.refs.video_recorder.stop_recording().then(() => {
                    this.resetOrder()
                  }
                  )
                }}>
                  <i className='ion-md-close pull-right'></i>
                </a> : ''
              }

            </div>
            <div className="panel-body">
              {
                this.state.status == DONE ?
                  <a data-toggle="collapse" href="#collapseOne">
                    <div className="check_mark">
                      <div className="sa-icon sa-success animate">
                        <span className="sa-line sa-tip animateSuccessTip"></span>
                        <span className="sa-line sa-long animateSuccessLong"></span>
                        <div className="sa-placeholder"></div>
                        <div className="sa-fix"></div>
                      </div>
                    </div>
                  </a> : ''
              }
              {
                this.state.status == WAITING_CONFIRM ?
                  <h1 className='text-warning text-center'>
                    所有品項已檢核，請刷訂單或結單條碼
                  </h1> : ''
              }

              <div className={`collapse ${this.state.status == WAITING_CONFIRM ? '' : 'in'}`} id='collapseOne'>
                <table className="table qc-table">
                  <thead>
                    <tr>
                      <th>品名</th>
                      <th>條碼</th>
                      <th>
                        {
                          this.state.accept_batch ? '批號' : '效期'
                        }
                      </th>
                      <th>備註</th>
                      <th>已確認</th>
                      <th>總數</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((product) => {
                      return <tr key={`${product.index}`} ref={(ref) => { this.state.highlight == product.index ? this.currentProductRef = ref : '' }} style={this.state.highlight == product.index ? { background: 'cornsilk' } : {}}>
                        <td>
                          {product.name}/{product.storage_type_name}
                          <br />
                          {
                            this.state.shelves.filter(shelf => {
                              return shelf.product_id == product.id
                            }).map((shelf) => {
                              return <span className="label label-pill qc-shelf" key={shelf.shelf_token}>{shelf.shelf_token}</span>
                            })
                          }
                        </td>
                        <td>
                          {product.barcode}
                        </td>
                        <td>
                          {this.state.accept_batch ? product.batch : product.expiration_date}
                        </td>
                        <td style={{ color: '#337ab7' }}>
                          {[product.note, product.internal_note].filter(e => e).join("/")}
                        </td>
                        <td>
                          {product.scanned}
                        </td>
                        <td>
                          {product.quantity}
                        </td>
                        <td>
                          {
                            product.scanned == product.quantity ?
                            <i className='text-success ion-md-checkmark'></i> :
                            <a className='btn btn-success btn-outline' onClick={() => {
                              this.onBatchCheckClick(product.uid, product.index)
                            }}>批量確認</a>
                          }
                        </td>
                      </tr>
                    })
                    }
                  </tbody>
                </table>
              </div>
              <hr></hr>
              {
                this.state.ready_to_packing.map(packing_item => {
                  let packing_items = []
                  if (packing_item.serial_numbers[0]) {
                    for (let [index, serial_number] of packing_item.serial_numbers.entries()) {
                      packing_items.push(
                        <a className="btn btn-sm label-pill btn-outline btn-success"
                          style={{ margin: 3 }} onClick={() => {
                            let _packing = this.state.ready_to_packing
                            let _products = this.state.products
                            for (let _packing_item of _packing)
                              if (_packing_item == packing_item) {
                                _packing_item.quantity -= 1
                                if (_packing_item.quantity == 0)
                                  _packing = _packing.filter(val => val !== _packing_item)
                                else
                                  _packing_item.serial_numbers.splice(index, 1)
                                console.log(index)
                                this.setState({ ready_to_packing: _packing })

                                if (this.state.status == WAITING_CONFIRM)
                                  this.setState({ status: CHECKING })
                                break
                              }
                            for (let _product of _products) {
                              if (_product.id == packing_item.product_id) {
                                _product.scanned -= 1
                                this.setState({ products: _products })
                                break
                              }
                            }
                          }} key={++count}
                        >
                          {`${serial_number}`}
                        </a>
                      )
                    }
                    packing_items.push(<br key={++count} />)
                  }
                  return packing_items
                })
              }
              {
                this.state.ready_to_packing.reduce((a, b) => { return a + b.serial_numbers.length }, 0) > 0 ?
                  <span className='pull-right'>{`共 ${this.state.ready_to_packing.reduce((a, b) => { return a + b.serial_numbers.length }, 0)} 筆序號`}</span> : ''
              }
              {
                this.state.order_note && <div className='row'>
                  <div className='col-md-1'>
                    <span style={{fontSize: 20,fontWeight:900}}>備註</span>
                  </div>
                  <div className='col-md-11'>
                    <span className='text-warning' style={{fontSize: 20,fontWeight:900}}>{this.state.order_note}</span>
                  </div>
                </div>
              }
              {this.state.consumables.filter(e => e.quantity > 0).length > 0 && <div className='row'>
                <div className='col-md-1'>
                  <span style={{fontSize: 20,fontWeight:900}}>耗材</span>
                </div>
                <div className='col-md-9'>
                  {
                    this.state.consumables.filter(e => e.quantity > 0).map(consumable => {
                      return <a className="btn btn-sm label-pill btn-outline btn-warning"
                        style={{ margin: 3 }} onClick={() => {

                          let consumables = this.state.consumables
                          for (let _consumable of consumables) {
                            if (_consumable.id == consumable.id) {
                              consumable.quantity -= 1
                              this.setState({ consumables: consumables })
                              this.setShippingAccountByConsumables(consumables)
                              break
                            }
                          }
                        }} key={consumable.id}>{`${consumable.title} x ${consumable.quantity}`}</a>
                    })
                  }
                </div>
                <div className='col-md-2'>
                  {this.state.order_status == 'done' && <a className='btn btn-success btn-outline' onClick={() => {
                    this.updateConsumables()

                  }}>更新耗材</a>}
                </div>
              </div>}
            </div>
            {footer_buttons}
          </div>
        </div>

        <div className='col-md-3'>
          <Video ref="video_recorder" onPause={this.onRecordingPause} onResume={this.onRecordingResume} />
          {
            this.state.uploading_videos > 0
              ? <div>
                <span className="lds-ellipsis"><div></div><div></div><div></div><div></div></span>
                {`錄影上傳中(${this.state.uploading_videos})`}
              </div>
              : ''

          }
          {
            this.state.id
              ? <PackingList
                onClearAllDone={this.onClearAllDone}
                packing_index={this.state.packing_index}
                authenticity_token={this.props.authenticity_token}
                print_packing={this.state.print_packing}
                items={this.state.ready_to_packing}
                onPressPacking={this.onPressPacking}
                onPrintPackingChange={(print) => {
                  this.setState({ print_packing: print })
                }}
                packings={this.state.packings}
                order_id={this.state.id}
              />
              : ''
          }
        </div>
      </div>
    </Provider>
    )
  }
}