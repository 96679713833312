import React, {useState} from 'react';
import _ from "lodash";

import AsyncSelect from 'react-select/async'

export default function ProductMultiSelect(props) {


  const {name,selectedProducts=[],multiple,preloadProducts=[],onChange,onBlur,placeholder="請選擇商品"} = props
  const [selectedOptions, setSelectedOptions] = useState(selectedProducts.map(product=>toOption(product)))
  const [loadedOptions, setLoadedOptions] = useState(()=>{
    return [...preloadProducts.map(product=>toOption(product)), ...selectedOptions]
  })
  const csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  function loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }

    fetch(`/admin/products.json?search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrf_token
      },
      credentials: 'same-origin'
    }).then(res => res.json()).then(data => {
      let options = data.products.map(product=>{
        return toOption(product)
      })
      for(let option of loadedOptions){
        if(!options.find(o=>o.value === option.value)){
          options.push(option)
        }
      }
      setLoadedOptions(options)
      callback(options)
    })
  };

  const debouncedLoadOptions = _.debounce(loadOptions, 500);

  function toOption(product){
    return { value: product.id, label: `${product.uid} ${product.name}` }
  }



  return <AsyncSelect
    name={name}
    className='react-select-container'
    classNamePrefix="react-select"
    placeholder={placeholder}
    cacheOptions={true}
    isMulti={multiple}
    defaultValue={selectedOptions}
    onChange={(value) =>{
      if (onChange) {
        onChange(value)
      }

    }}
    onBlur={onBlur ? onBlur : ()=>{}}
    loadOptions={(input,callback)=>debouncedLoadOptions(input,callback)}
    defaultOptions={ [...loadedOptions, {label: "輸入兩碼以上關鍵字..." ,value: undefined, isDisabled: true}] }
  />

}
